




























































import { Component, Mixins, Ref, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { formatDate } from "#/utility/appDate";
import { VForm } from "@/types";
import { CareplanOffice } from "./careplan";

interface CareplanLinkage {
  id: number;
  office_id: number;
  office_name: string;
  relation_institution_id: number;
  relation_institution_name: string;
  created_at: string;
  care_plan_files: CareplanLinkageFile[];
}

interface CareplanLinkageFile {
  id: number;
  file_type: number;
  file_name: string;
  file_path: string;
}

const FileType = {
  up1kyo: 1,
  up2kyo: 2,
  up3kyo: 7,
  upplan: 3,
  upsikyu: 4,
  plan_hosoku: 5,
  schedule_hosoku: 6,
  kihon: 8,
  kihon_sub1: 9,
  kihon_sub2: 10,
  kihon_image: 11,
  yobo: 12,
  yobo_sub: 13,
  up1kyo_delete: 91,
  upplan_delete: 93,
  upkihon_delete: 94,
  upyobo_delete: 95
} as const;

@Component
export default class CareplanHistory extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;
  @Prop({ default: () => [] }) offices!: CareplanOffice[];

  private officeId = 0;
  private isLoading = false;
  private linkages: CareplanLinkage[] = [];
  private headers = [
    {
      text: "取込日時",
      value: "created_at",
      sortable: true,
      width: "172px"
    },
    {
      text: "送信元機関",
      value: "relation_institution_name",
      sortable: false,
      width: "220px"
    },
    {
      text: "実行職員",
      value: "staff_name",
      sortable: false
    },
    {
      text: "バージョン",
      value: "version",
      sortable: false,
      width: "80px"
    },
    {
      text: "取込ファイル情報",
      value: "file_info",
      sortable: false,
      width: "300px"
    },
    {
      text: "",
      value: "file_name",
      sortable: false,
      width: "580px"
    }
  ];

  private get officeChoices() {
    return this.offices
      .filter(office => {
        return !office.isSatelite;
      })
      .map(office => {
        return {
          text: office.name,
          value: office.id
        };
      });
  }

  private created() {
    if (this.loginUser.parent_office_id > 0) {
      // 主たる事業所のみ表示するので初期値は主たる事業所にする
      this.officeId = this.loginUser.parent_office_id;
    } else {
      // 未所属は未選択
    }
  }

  private search() {
    if (!this.form.validate()) {
      return;
    }

    this.fetch();
  }

  private getFileTypeName(type: number) {
    switch (type) {
      case FileType.up1kyo:
        return "居宅サービス計画書1表";
      case FileType.up2kyo:
        return "居宅サービス計画書2表";
      case FileType.up3kyo:
        return "居宅サービス計画書3表";
      case FileType.upplan:
        return "サービス利用票";
      case FileType.upsikyu:
        return "サービス利用票別表";
      case FileType.plan_hosoku:
        return "利用者補足情報";
      case FileType.schedule_hosoku:
        return "利用者補足情報";
      case FileType.kihon:
        return "利用者基本情報";
      case FileType.kihon_sub1:
        return "利用者基本情報(別表1)";
      case FileType.kihon_sub2:
        return "利用者基本情報(別表2)";
      case FileType.kihon_image:
        return "家族構成画像ファイル";
      case FileType.yobo:
        return "介護予防サービス・支援計画書";
      case FileType.yobo_sub:
        return "介護予防サービス・支援計画書(別表)";
      case FileType.up1kyo_delete:
        return "居宅サービス計画書_削除";
      case FileType.upplan_delete:
        return "サービス利用票_削除";
      case FileType.upkihon_delete:
        return "利用者基本情報_削除";
      case FileType.upyobo_delete:
        return "介護予防サービス・支援計画書_削除";
    }

    return "";
  }

  private formatDate(str: string) {
    return formatDate(str, "YYYY年M月D日(dd)");
  }

  private formatTime(str: string) {
    return formatDate(str, "H時m分");
  }

  /** CSVファイルダウンロード */
  private download(path: string, name: string) {
    this.postJsonBlobResCheck(
      window.base_url + "/api/careplan/csv/download",
      { file_path: path },
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = name;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /** ケアプラン連携情報取得 */
  private fetch() {
    this.isLoading = true;

    this.postJsonCheck(
      window.base_url + "/api/careplan/history/get",
      {
        office_id: this.officeId
      },
      res => {
        this.isLoading = false;
        this.linkages = res.data.care_plan_linkage_histories;
      }
    );
  }
}
