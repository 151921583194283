import Base from "../base";
import { CarePlanLinkageFile } from "./carePlanLinkageFile";
import { CarePlan } from "./carePlan";

/** ケアプラン連携情報 */
export interface CarePlanLinkage extends Base {
  /** ID */
  id: number;
  /** 契約ID */
  agreement_id: number;
  /** 関連機関ID */
  relation_institution_id: number;
  /** 事業所ID */
  office_id: number;
  /** バージョン 0:v3 1:v4 */
  version: number;
  /** ケアプラン連携ファイル情報 */
  care_plan_files: CarePlanLinkageFile[];
  /** ケアプラン情報  */
  care_plans: CarePlan[];
}

/** ケアプラン連携情報デフォルト値 */
export const DEFAULT_CARE_PLAN_LINKAGE: CarePlanLinkage = {
  id: 0,
  agreement_id: 0,
  relation_institution_id: 0,
  office_id: 0,
  version: 0,
  care_plan_files: [],
  care_plans: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
};
