
















import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { COLLECTION_RECEIPT_PRE_CHECK } from "@/const/envFireStore";
import { PreCheckState } from "@/views/reciept/types";

@Component
export default class PreCheckButton extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin
) {
  @Prop({ type: String, required: true }) readonly yearmonth!: string;

  /** 無効状態 */
  @Prop({ default: false }) private readonly disabled!: boolean;

  internalYearmonth = "";
  internalOfficeId = 0;
  private isProcessing = false;

  @Watch("yearmonth")
  onYearmonthChanged(newValue: string) {
    this.internalYearmonth = newValue;
  }

  public detach = () => {
    return;
  };

  created() {
    this.collection = COLLECTION_RECEIPT_PRE_CHECK;
  }

  // 処理中かどうかの判定などを行う
  public setPreCheckState(officeId: number) {
    // リアルタイムアップデート用リスナーを作成
    this.createListener(officeId);
  }

  beforeDestroy() {
    // リアルタイムアップデート用リスナーをデタッチ
    this.detach();
  }

  /** リアルタイムアップデート用リスナーの作成 */
  private createListener(officeId: number) {
    const docId = this.createDocumentId(officeId);
    this.detach = this.getListner(
      (query) => {
        return query.where("id", "==", docId);
      },
      (docChange) => {
        const stateData = docChange.doc.data() as PreCheckState;
        stateData.id = docChange.doc.id;
        // 5分以上前の書き込みなら無いものとして扱う（安全装置）
        const isFiveMinutesPassed = this.isFiveMinutesPassed(stateData.time);
        if (isFiveMinutesPassed) {
          return;
        }

        this.isProcessing = stateData.status_flag == 1;
      }
    );
  }

  private createDocumentId(officeId: number) {
    return String(officeId);
  }

  private handleClick(event: MouseEvent) {
    this.$emit("click", event);
  }

  // 5分以上前か比較
  private isFiveMinutesPassed(timeString: string) {
    const targetDate = new Date(timeString);
    const now = new Date();
    const diff = now.getTime() - targetDate.getTime();
    const fiveMinutesInMilliseconds = 300000;

    return Math.abs(diff) > fiveMinutesInMilliseconds;
  }
}
