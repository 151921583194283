














































































































































import { Component, Mixins, Ref, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import { Careplan, CareplanOffice } from "./careplan";
import AppSelectTable from "#/components/AppSelectTable.vue";
import { thisMonth } from "#/utility/appDate";
import { SortWarekiDate } from "#/utility/appSort";
import { DataTableHeader } from "vuetify";

const ServiceType = {
  plan: 1,
  schedule: 2,
  kihonYobo: 3
} as const;

@Component({
  components: {
    AppSelectTable
  }
})
export default class CareplanList extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: () => [] }) offices!: CareplanOffice[];

  private officeIds: number[] = [];
  private serviceType = 2;
  private targetYearMonth = "";
  private isLoading = false;
  private viewType = 2;
  private filterCharacter = "";
  private careplans: Careplan[] = [];
  private selectedCareplans: Careplan[] = [];

  /** 並び替えに使用する項目 */
  private sortBy: string[] = [
    "deleted_datetime",
    "link_date",
    "patient_name_kana"
  ];

  /** 並び替え昇順/降順 */
  private sortDesc: boolean[] = [true, true, true];

  // 選択肢の状態（セレクトボックスと同値）
  private get isPlanMode() {
    return this.serviceType === ServiceType.plan;
  }

  private get isScheduleMode() {
    return this.serviceType === ServiceType.schedule;
  }

  private get isKihonYoboMode() {
    return this.serviceType === ServiceType.kihonYobo;
  }

  // 画面の状態（検索をトリガーとして変わる）
  private get isPlanViewMode() {
    return this.viewType === ServiceType.plan;
  }

  private get isScheduleViewMode() {
    return this.viewType === ServiceType.schedule;
  }

  private get isKihonYoboViewMode() {
    return this.viewType === ServiceType.kihonYobo;
  }

  private get tableHeaders() {
    const headers: DataTableHeader[] = [
      {
        text: "ID",
        value: "patient_display_id"
      },
      {
        text: "利用者名",
        value: "patient_name"
      },
      {
        text: "ふりがな",
        value: "patient_name_kana"
      },
      {
        text: "居宅介護支援事業所名",
        value: "care_provider_name",
        filterable: false
      },
      {
        text: "担当者名",
        value: "care_manager_name",
        filterable: false
      }
    ];

    if (this.isPlanViewMode) {
      headers.push({
        text: "居宅サービス計画作成(変更)日",
        value: "created_date_str",
        filterable: false,
        sort: SortWarekiDate
      });
      headers.push({
        text: "バージョン",
        value: "version",
        filterable: false
      });
    } else if (this.isScheduleViewMode) {
      headers.push({
        text: "作成年月日",
        value: "created_date_str",
        filterable: false,
        sort: SortWarekiDate
      });
      headers.push({
        text: "バージョン",
        value: "version",
        filterable: false
      });
    } else if (this.isKihonYoboViewMode) {
      headers.push({
        text: "相談日/計画作成(変更)日",
        value: "created_date_str",
        filterable: false,
        sort: SortWarekiDate
      });
      headers.push({
        text: "ファイル名称",
        value: "service_type",
        filterable: false
      });
    }

    headers.push(
      {
        text: "ケアマネ出力日時",
        value: "timestamp",
        filterable: false,
        sort: SortWarekiDate
      },
      {
        text: "取込日時",
        value: "link_date",
        filterable: false,
        sort: SortWarekiDate
      },
      {
        text: "削除日時",
        value: "deleted_datetime",
        filterable: false,
        sort: SortWarekiDate
      }
    );

    return headers;
  }

  private get officeChoices() {
    return this.offices.map(office => {
      return {
        text: office.name,
        value: office.id
      };
    });
  }

  private created() {
    if (this.loginUser.office_id > 0) {
      this.officeIds.push(this.loginUser.office_id);
    } else {
      // 未所属は未選択
    }

    this.targetYearMonth = thisMonth();
  }

  private search() {
    if (!this.form.validate()) {
      return;
    }

    this.isLoading = true;

    this.postJsonCheck(
      window.base_url + "/api/careplan/careplans/get",
      {
        office_ids: this.officeIds,
        target_yearmonth: this.targetYearMonth,
        service_type: this.serviceType,
        is_need_latest_one: this.serviceType == ServiceType.schedule ? 0 : 1,
        is_need_withdrawn: this.serviceType == ServiceType.schedule ? 1 : 0
      },
      res => {
        this.isLoading = false;
        this.careplans = res.data.care_plan_list;
        // チェック状態初期化
        this.selectedCareplans = [];
        // 画面タイプ切り替え
        this.viewType = this.serviceType;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  private requiredOffices(officeIds: number[]) {
    return officeIds.length > 0 || "事業所を選択してください";
  }

  private recordClass(item: Careplan) {
    if (item.is_deleted) {
      return "careplan-list-table-record-delete";
    }

    return "";
  }

  private toPatientPage(patientId: number) {
    this.$router.push({
      path: `/patient/${patientId}/careplan`,
      query: {
        serviceType: String(this.viewType),
        targetYearmonth: String(this.targetYearMonth)
      }
    });
  }

  private previewPlans() {
    const carePlanIds = this.selectedCareplans.map(careplan => {
      return careplan.care_plan_id;
    });

    if (carePlanIds.length === 0) {
      this.$openAlert("表示可能なデータがありません");
      return;
    }

    let path = "";
    let param;
    if (this.isPlanViewMode) {
      path = "plan/preview";
      param = {
        service_plan_ids: carePlanIds
      };
    } else if (this.isScheduleViewMode) {
      path = "schedule/preview";
      param = {
        service_schedule_ids: carePlanIds,
        target_yearmonth: this.targetYearMonth
      };
    } else {
      // failsafe
      return;
    }

    this.makePdf(window.base_heavy_url + "/api/careplan/" + path, param);
  }
}
