




































import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { DataTableHeader } from "vuetify/types/index";
import {
    PatientInfo,
    ReceiptSearchCondition,
    DefaultPatientInfo,
    DefaultReceiptSearchCondition,
} from "#/model/receipt";

@Component({
    components: {
    }
})
export default class PreCheckPatientTable extends Mixins(
    UtilMixin,
    AxiosMixin,
    FireStoreMixin,
) {
    /** 選択種別 0:複数名・同一建物 1:複数名 2:同一建物 */
    @Prop({ default: () => 0 }) type!: number;

    /** 対象年月 */
    @Prop({ default: () => "" }) yearmonth!: string;

    /** 利用者一覧 */
    @Prop({ default: () => [] }) patients!: PatientInfo[];

    /** チェック対象利用者 */
    @Prop({ default: () => { DefaultPatientInfo() } }) targetPatient!: PatientInfo;

    /** 選択済み利用者一覧 */
    @Prop({ default: () => [] }) selectedPatients!: PatientInfo[];

    /** キーワード */
    @Prop({ default: () => "" }) searchPatientName!: string;

    /** レスポンスのままフィルタリングしていない利用者一覧 */
    private unfilterPatients: PatientInfo[] = [];

    /** 選択された利用者一覧 */
    private selectPatients: PatientInfo[] = [];

    /** firestoreに保存する条件 */
    private condition = DefaultReceiptSearchCondition();

    private pageCount = 0; // ページ件数

    private pageNo = 1; // ページ番号

    private itemPerPage = 10;   // 1ページあたりの表示数

    //データテーブル
    private headers: DataTableHeader[] = [
        {
            text: "利用者",
            value: "name",
            width: "9.2rem",
            class: "pl-4",
            cellClass: "pl-4 text-pre-wrap",
        },
        {
            text: "ふりがな",
            value: "furigana",
            width: "9rem",
            class: "pl-0",
            cellClass: "pl-0",
        },
        {
            text: "対象年月",
            value: "target_yearmonth",
            width: "calc(7.5rem - 16px)",
            class: "pl-0",
            cellClass: "pl-0",
        },
        {
            text: "複数名加算",
            value: "is_process_fukusu_qualif",
            width: "calc(7.5rem - 16px)",
            class: "pl-0",
            cellClass: "pl-0",
        },
        {
            text: "同一建物減算",
            value: "is_process_same_building",
            width: "calc(7.5rem - 16px)",
            class: "pl-0",
            cellClass: "pl-0",
        }
    ];

    ALL = 0;
    FUKUSU = 1;
    SAME_BUILDING = 2;

    @Watch("patients") watchPatients() {
        this.unfilterPatients = this.patients;

        this.resetProcessPatients();
        this.setDefaultSelectPatients();
    }

    @Watch("targetPatient") watchTargetPatient() {
        this.resetProcessPatients();
        this.setDefaultSelectPatients();
    }

    @Watch("FilterPatients") watchFilterPatients() {
        this.condition.ids = this.FilterPatients.map((patient) => {
            return Number(patient.patient_id);
        });
    }

    @Watch("selectPatients", { deep: true }) watchSelectPatients() {
        this.$emit("change-select-patients", this.selectPatients);
    }

    @Watch("condition.search_cond.patient_name") watchPatientName() {
        this.$emit("change-patient-name", this.condition.search_cond.patient_name);
    }

    public async created() {
        this.unfilterPatients = this.patients;

        // 表示する表の種別によって、デフォルトのソート順を指定
        this.setDefaultSort();

        // 選択済み利用者の指定がある場合
        if (this.selectedPatients !== undefined) {
            this.selectPatients = this.selectedPatients;
        }

        // キーワードが指定されている場合
        if (this.searchPatientName !== undefined) {
            this.condition.search_cond.patient_name = this.searchPatientName;
        }
    }

    // 選択された利用者一覧の処理対象フラグを更新
    public clickCheck(item: PatientInfo) {
        const index = this.selectPatients.findIndex(patient => patient.patient_id === item.patient_id);

        if (index !== -1) {
            if (item.is_process_fukusu_qualif || item.is_process_same_building) {
                this.selectPatients[index].is_process_fukusu_qualif = item.is_process_fukusu_qualif;
                this.selectPatients[index].is_process_same_building = item.is_process_same_building;
            } else {
                // 複数名、同一建物のどちらも処理を行わない変更の場合は選択された利用者一覧から削除する
                this.selectPatients.splice(index, 1);
            }
        } else {
            this.selectPatients.push(item);
        }
    }

    // ページ変更
    private changePage(pageNo: number) {
        this.pageNo = pageNo;
    }

    /** 初期ソート順を指定 */
    private setDefaultSort() {
        this.condition.sort_by = ["furigana"];
        this.condition.sort_desc = [false];
    }

    /** 開かれた際に全利用者にチェックを入れた状態を初期値にする */
    private resetProcessPatients() {
        if (this.targetPatient !== undefined && this.targetPatient?.patient_id != 0) {
            return this.unfilterPatients.map(item => {
                if (item.patient_id == this.targetPatient.patient_id) {
                    item.is_process_fukusu_qualif = true;
                    item.is_process_same_building = false;
                }
            });
        } else {
            return this.unfilterPatients.map(item => {
                item.is_process_fukusu_qualif = true;
                item.is_process_same_building = true;
            });
        }
    }

    // 初期選択されている利用者を設定
    private setDefaultSelectPatients() {
        if (this.targetPatient !== undefined && this.targetPatient.patient_id != 0) {
            const patient = this.targetPatient;
            patient.is_process_fukusu_qualif = true;
            patient.is_process_same_building = false;
            this.selectPatients = [patient];
        } else {
            this.selectPatients = this.unfilterPatients;
        }
    }


    /** フィルターされた利用者 */
    private get FilterPatients() {
        if (!Array.isArray(this.unfilterPatients)) {
            return [];
        }

        if (this.condition === undefined) {
            return this.unfilterPatients;
        }

        return this.unfilterPatients
            .filter((p) => {
                // 利用者名・ふりがな絞り込み
                if (!this.condition.search_cond.patient_name) {
                    return true;
                }
                return (
                    this.matchText(
                        p.name.replace(/\s/g, ""),
                        this.condition.search_cond.patient_name.replace(/\s/g, ""),
                        this.condition.search_cond.patient_name_by
                    ) ||
                    this.matchText(
                        p.furigana.replace(/\s/g, ""),
                        this.condition.search_cond.patient_name.replace(/\s/g, ""),
                        this.condition.search_cond.patient_name_by
                    )
                );
            })
            .filter((p) => {
                // 利用者ID絞り込み
                if (!this.condition.search_cond.display_id) return true;
                return this.matchText(
                    p.display_id,
                    this.condition.search_cond.display_id,
                    this.condition.search_cond.display_id_by
                );
            })
            .map((p) => {
                p.fixed_date = p.fixed_date.replaceAll("-", "/");
                return p;
            });
    }

    /** 並び替え変更 */
    private changeSort(items: PatientInfo[]) {
        if (!items.length || this.condition === undefined) return;

        this.condition.ids = items.map((patient) => {
            return Number(patient.patient_id);
        });
    }

    /** データをグレーにする（請求履歴確定済のもの） ※データテーブルに渡す */
    private itemRowBackground(item: PatientInfo) {
        return item.invoice_history != "" ? "grey lighten-5" : "";
    }

    /** キーワード入力文字を検索条件に反映 ※データテーブルに渡す */
    private updateKeyword(newValue: string) {
        if (this.condition === undefined) return;

        this.condition.search_cond.patient_name = newValue;
    }

    /** 文字列一致を調べる */
    private matchText(
        subject: string,
        pattern: string,
        matchBy: number
    ): boolean {
        const matchIndex = subject.indexOf(pattern);
        switch (matchBy) {
            case 0: // 前方一致
                return matchIndex === 0;
            case 1: // 部分一致
                return matchIndex !== -1;
            default:
                return false;
        }
    }
}
