









































































































































































































































































































































































import { Component, Ref, Mixins, Watch } from "vue-property-decorator";
import { VForm } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import EditMixin from "@/mixins/editMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import AppPlaces from "#/components/AppPlaces.vue";
import DuplicateCheckDialog from "@/components/patient/info/DuplicateCheckDialog.vue";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import FamilyRelationShipSelect from "@/components/common_ibow/FamilyRelationShipSelect.vue";
import DateInput from "#/components/DateInput.vue";
import BasePatientDoctorSelect from "@/components/patient/common_ibow/base/BasePatientDoctorSelect.vue";
import BasePatientRelationInsSelect from "@/components/patient/common_ibow/base/BasePatientRelationInsSelect.vue";
import * as constant from "#/const";
import * as patient from "@/store/modules/patient/types";
import { Patient, DefaultPatient } from "#/model/patient";
import { PRIREQUESTER_TYPE } from "@/components/patient/info/const";

@Component({
  components: {
    AppPlaces,
    DuplicateCheckDialog,
    OfficeSelect,
    DateInput,
    FamilyRelationShipSelect,
    BasePatientDoctorSelect,
    BasePatientRelationInsSelect
  }
})
export default class PatientAccept extends Mixins(
  UtilMixin,
  EditMixin,
  AxiosMixin,
  RulesMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Ref("duplicateCheckDialog")
  private readonly duplicateCheckDialog!: DuplicateCheckDialog;

  /** 編集モードへの切り替え */
  @Watch("patient", { deep: true }) protected watchPatient() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  private breadItems = [
    {
      text: "利用者",
      disabled: false,
      to: "/patient/list"
    },
    {
      text: "利用者新規受付",
      disabled: true,
      to: ""
    }
  ];

  private PRIREQUESTER_TYPE = PRIREQUESTER_TYPE;

  /** 関連機関 */
  private relations: patient.RelationInstitutionPatient[] = [];

  /** 医療機関 */
  private medicals: patient.MedicalInstitutionPatient[] = [];

  /** 利用者情報(編集対象) */
  private patient: Patient = DefaultPatient();

  /** 訪問場所定数 */
  private VISIT_PLACE_DIV_FACILITY = constant.PATIENT.VISIT_PLACE_DIV_FACILITY;

  created() {
    this.fetch();
  }

  // 登録ボタン押下
  private async clickRegist() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    // 訪問場所
    if (
      this.patient.info.visit_place_div !=
      constant.PATIENT.VISIT_PLACE_DIV_FACILITY
    ) {
      this.patient.info.visit_place_facility_div = 0;
    }

    this.postJsonCheck(
      window.base_url + "/api/patients/accept/regist",
      { patient: this.patient },
      async res => {
        if (res.data) {
          if (res.data.err_messages && res.data.err_messages.length > 0) {
            // バリデーションエラーあり
            let message = "";
            (res.data.err_messages as string[]).forEach(err => {
              message += err;
            });
            await this.$openAlert(message);
            return;
          }
        }
        this.setNoEditMode();
        this.$router.go(-1);
      }
    );
  }

  // キャンセルボタン押下
  private clickCancel() {
    this.$router.go(-1);
  }

  // 重複登録チェックボタン押下
  private clickDuplicateName() {
    this.duplicateCheckDialog.open();
  }

  /** マスタデータ取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/patients/accept/get",
      { office_id: this.patient.info.office_id },
      res => {
        if (res.data) {
          this.relations = res.data.relation_institutions;
          this.medicals = res.data.medical_institutions;
          this.patient.info.office_id = this.loginUser.office_id;
          this.setLoaded();
        } else {
          this.setLoaded();
        }
      }
    );
  }

  /** 関連機関変更に合わせて利用者情報更新 */
  private changeRelation(id: number) {
    this.changeRquester(this.relations, id);
  }

  /** 医療機関変更に合わせて利用者情報更新 */
  private changeMedical(id: number) {
    this.changeRquester(this.medicals, id);
  }

  /** 関連機関・医療機関の変更に合わせて利用者の受付情報に電話番号を自動フィルイン */
  private changeRquester(
    choice: {
      id: number;
      tel_area: string;
      tel_city: string;
      tel_num: string;
    }[],
    id: number
  ) {
    // 渡されたIDに一致する機関情報を元に、電話番号を反映
    const master = choice.filter(item => item.id == id)[0];
    if (!master) {
      return;
    }

    // 市外局番
    this.patient.info.requester_tel_area = master.tel_area;
    // 市内局番
    this.patient.info.requester_tel_city = master.tel_city;
    // 加入者番号
    this.patient.info.requester_tel_num = master.tel_num;
  }
}
