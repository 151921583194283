var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('AppButton',{attrs:{"color":"primary","outlined":"","height":"48px","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2),(_vm.isProcessing)?_c('v-chip',{staticClass:"ibow2-ai-report-info white--text px-0",style:({
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    zIindex: '50',
    width: '50px',
    height: '20px',
  }),attrs:{"color":"#e91e63"}},[_vm._v("処理中")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }