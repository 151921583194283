



















































import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { LinkagePreview } from "../careplan";

@Component
export default class CareplanInputResultDialog extends Vue {
  @Prop({ default: () => [] }) linkagePreviews!: LinkagePreview[];
  @Prop({ default: false }) isOpenDialog!: boolean;
  @Prop({ default: 1 }) inputType!: number;

  @Emit()
  private cancel() {
    return;
  }

  @Emit()
  private deleteData() {
    return;
  }
}
