
















































import { Component, Mixins, Ref, Prop } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import CareplanOutput from "./CareplanOutput.vue";
import CareplanInput from "./CareplanInput.vue";
import CareplanInputResultDialog from "./CareplanInputResultDialog.vue";
import CareplanDeleteResultDialog from "./CareplanDeleteResultDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import {
  RelationInstitution,
  LinkagePreview,
  CareplanOffice,
  CarePlanChoice
} from "../careplan";
import { CarePlanLinkage, DEFAULT_CARE_PLAN_LINKAGE } from "#/model/careplan";
import { uuid } from "vue-uuid";

const Mode = {
  input: 1,
  output: 2
} as const;

const InputType = {
  plan: 1,
  schedule: 2,
  kihon: 3,
  yobo: 4,
  kihonYobo: 5
} as const;

interface LinkageFileSet {
  hosokuFile: File;
  fisrtFile: File;
  secondFile: File;
}

/** ファイルキー情報 */
interface FileKey {
  /** ファイル名 */
  name: string;
  /** ファイルパス */
  path: string;
}

@Component({
  components: {
    OfficeSelect,
    CareplanInput,
    CareplanOutput,
    CareplanInputResultDialog,
    CareplanDeleteResultDialog
  }
})
export default class CareplanInOut extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: () => [] }) offices!: CareplanOffice[];

  private mode = 1;
  private institutions: RelationInstitution[] = [];
  private isOpenInputResultDialog = false;
  private isOpenDeleteResultDialog = false;
  private inputType = 0;

  // ファイル取り込み時に必要なデータを一時保存してくためのもの
  private linkage: CarePlanLinkage = { ...DEFAULT_CARE_PLAN_LINKAGE };
  private careplans = [];
  private linkagePreviews: LinkagePreview[] = [];

  private modes = [
    {
      value: Mode.input,
      text: "取込"
    },
    {
      value: Mode.output,
      text: "出力"
    }
  ];

  private get isInputMode() {
    return this.mode === Mode.input;
  }

  private get isOutputMode() {
    return this.mode === Mode.output;
  }

  private created() {
    this.fetchRelationInstitutions();
  }

  private fetchRelationInstitutions() {
    this.postJsonBackground(
      window.base_url + "/api/careplan/relationinstitutions/get",
      {},
      res => {
        this.institutions = res.data.relation_institutions;
      }
    );
  }

  private inputPlan(data: {
    officeId: number;
    institutionId: number;
    files: File[];
  }) {
    this.checkInputFiles(
      InputType.plan,
      data.officeId,
      data.institutionId,
      data.files
    );
  }

  private inputSchedule(data: {
    officeId: number;
    institutionId: number;
    files: File[];
  }) {
    this.checkInputFiles(
      InputType.schedule,
      data.officeId,
      data.institutionId,
      data.files
    );
  }

  private inputKihonYobo(data: {
    officeId: number;
    institutionId: number;
    files: File[];
  }) {
    this.checkInputFiles(
      InputType.kihonYobo,
      data.officeId,
      data.institutionId,
      data.files
    );
  }

  private checkInputFiles(
    inputType: number,
    officeId: number,
    institutionId: number,
    files: File[]
  ) {
    let path = "";
    switch (inputType) {
      case InputType.plan:
        path = "/api/careplan/plan/check";
        break;
      case InputType.schedule:
        path = "/api/careplan/schedule/check";
        break;
      case InputType.kihonYobo:
        path = "/api/careplan/yobo/check";
    }

    this.uploadFiles(files, obj => {
      this.postJsonCheck(
        window.base_url + path,
        {
          office_id: officeId,
          relation_institution_id: institutionId,
          files: obj
        },
        res => {
          this.linkagePreviews = res.data.linkage_preview;
          this.linkage = res.data.care_plan_linkage;
          this.inputType = inputType;
          this.isOpenInputResultDialog = true;
        },
        () => {
          //
        }
      );
    });
  }

  private inputDeletePlan(data: {
    officeId: number;
    institutionId: number;
    deleteCSV: File;
  }) {
    this.checkDeleteFile(
      InputType.plan,
      data.officeId,
      data.institutionId,
      data.deleteCSV
    );
  }

  private inputDeleteSchedule(data: {
    officeId: number;
    institutionId: number;
    deleteCSV: File;
  }) {
    this.checkDeleteFile(
      InputType.schedule,
      data.officeId,
      data.institutionId,
      data.deleteCSV
    );
  }

  private inputDeleteKihon(data: {
    officeId: number;
    institutionId: number;
    deleteCSV: File;
  }) {
    this.checkDeleteFile(
      InputType.kihon,
      data.officeId,
      data.institutionId,
      data.deleteCSV
    );
  }

  private inputDeleteYobo(data: {
    officeId: number;
    institutionId: number;
    deleteCSV: File;
  }) {
    this.checkDeleteFile(
      InputType.yobo,
      data.officeId,
      data.institutionId,
      data.deleteCSV
    );
  }

  private checkDeleteFile(
    inputType: number,
    officeId: number,
    institutionId: number,
    file: File
  ) {
    let apiPath = "";
    switch (inputType) {
      case InputType.plan:
        apiPath = "/api/careplan/plan/delete/check";
        break;
      case InputType.schedule:
        apiPath = "/api/careplan/schedule/delete/check";
        break;
      case InputType.kihon:
        apiPath = "/api/careplan/kihon/delete/check";
        break;
      case InputType.yobo:
        apiPath = "/api/careplan/yobo/delete/check";
    }

    this.uploadFiles([file], fileKeys => {
      this.postJsonCheck(
        window.base_url + apiPath,
        {
          office_id: officeId,
          relation_institution_id: institutionId,
          files: fileKeys
        },
        res => {
          this.linkagePreviews = res.data.linkage_preview;
          this.linkage = res.data.care_plan_linkage;
          this.careplans = res.data.care_plans;
          this.inputType = inputType;
          this.isOpenDeleteResultDialog = true;
        }
      );
    });
  }

  /** 複数ファイルアップロード */
  private uploadFiles(
    files: File[],
    success: (objs: { name: string; path: string }[]) => void
  ) {
    // ファイルキー情報
    const keys: FileKey[] = [];
    // フォームデータ
    const formData = new FormData();

    files.forEach((file: File) => {
      const path = `${this.siteName}/kst_no/care_plan/csv/0/${uuid.v4()}`;
      formData.append(file.name, file);
      keys.push({ name: file.name, path: path });
    });

    formData.append("keys", JSON.stringify(keys));
    formData.append("is_temp", "1");

    this.postJsonCheck(
      window.base_url + "/api/uploads",
      formData,
      () => {
        success(keys);
      },
      () => {
        //
      }
    );
  }

  private input() {
    if (!this.linkage || this.linkage.care_plans.length === 0) {
      this.$openAlert("取込可能な利用者がいません");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/careplan/input",
      {
        care_plan_linkage: this.linkage
      },
      () => {
        this.completeInput();
        this.$openAlert(
          "取込完了しました。取り込んだ帳票は取込済みケアプラン一覧タブでご確認ください。"
        );
      }
    );
  }

  private cancelInput() {
    this.completeInput();
  }

  private completeInput() {
    this.isOpenInputResultDialog = false;
    this.linkagePreviews = [];
    this.linkage = { ...DEFAULT_CARE_PLAN_LINKAGE };
  }

  private deleteData() {
    if (this.careplans.length === 0) {
      this.$openAlert("削除可能な利用者がいません");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/careplan/delete/input",
      {
        care_plan_linkage: this.linkage,
        care_plans: this.careplans
      },
      () => {
        this.completeDelete();
        this.$openAlert(
          "取込完了しました。取り込んだ帳票は取込済みケアプラン一覧タブでご確認ください。"
        );
      }
    );
  }

  private cancelDelete() {
    this.completeDelete();
  }

  private completeDelete() {
    this.isOpenDeleteResultDialog = false;
    this.linkagePreviews = [];
    this.linkage = { ...DEFAULT_CARE_PLAN_LINKAGE };
    this.careplans = [];
  }

  /** 実績情報出力 */
  private output(data: {
    officeChoice: CarePlanChoice;
    rInstChoice: CarePlanChoice;
    yearMonth: string;
    version: number;
    serviceKindCode: number;
  }) {
    // ファイル名決定
    const now = new Date();
    const nowStr = this.dateToStr(now, "yyyyMMddHHmmss");
    const targetYearmonthStr = data.yearMonth.replaceAll("-", "");
    const fileName =
      data.version === 0
        ? `UPJSK_${targetYearmonthStr}_${data.officeChoice.office_code}_${data.rInstChoice.office_code}_${nowStr}.csv`
        : `UPJSK_${targetYearmonthStr}_${data.officeChoice.office_code}_${data.serviceKindCode}_${data.rInstChoice.office_code}_${data.serviceKindCode}_${nowStr}.csv`;

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/careplan/csv/output",
      {
        relation_institution_id: Number(data.rInstChoice.value),
        office_id: Number(data.officeChoice.value),
        target_yearmonth: data.yearMonth,
        version: data.version,
        service_kind_code: data.serviceKindCode
      },
      async res => {
        if (!res.data.size) {
          await this.$openAlert(
            "出力するデータがありません。出力条件を見直し、もう一度出力してください。"
          );
          return;
        }
        const blob = new Blob([res.data]);
        const a = document.createElement("a");
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.click();
      }
    );
  }
}
